import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
import useLoadScript from "../Utility/useLoadScript";
import ExternalScriptsLoader from "../Scripts/Home_colp_lps";
import HowItWorks from "../components/HowItWorks";
import Contact from "../components/Contact";
import HeroImage from '../assets/images/photos/hero3.jpg';
import ContactImage from '../assets/images/photos/ct.png';
import WhyChooseUs from "../components/WhyChooseUs";
import Services from "../components/Services";
export default function Bng_lp1() {
  useLoadScript("../Scripts/Home_colp_lps.js");
  const HeroContent = {
    Heading: `Effortless Moving Solutions`,
    SubHeading: `Experience Stress-Free Relocation`,
    Text: `` , 
    Image: HeroImage ,
  }
  const ContactContent = {
    Heading: `Don't Let Moving Wear You Down
Experience A Hassle-Free Move`,
    Image: ContactImage ,
  }
  const PhoneNumber = {
    PhoneNo: "(855) 733-0794",
  };
  return (
    <div>
      <ExternalScriptsLoader />
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection HeroContent={HeroContent} PhoneNumber={PhoneNumber}/>
      <WhyChooseUs PhoneNumber={PhoneNumber} />
      
      <Services PhoneNumber={PhoneNumber} />
     
      <Contact ContactContent={ContactContent} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
