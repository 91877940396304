import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";


export default function HowItWorks({ PhoneNumber }) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach(ref => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };
 
  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
   <div className="HowWorks">
    <div className="workContnt">
      <h4>We Simplify Your Move</h4>
      <p>Moving can be a hassle – time-consuming, stressful, and expensive.
      Avoid the headaches and let us connect you with professionals to ensure a smooth and efficient move, letting you focus on your new home or office.</p>
    </div>


  
   </div>
  );
}
