import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";
import Image from '../assets/images/photos/hero.png';

export default function Contact({ PhoneNumber , ContactContent }) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach((ref) => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);

  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;

  return (
    <div className="contact-section">
      <section className="contact-container">
      <img src={ContactContent.Image} alt="Hero" />
        <div className="contact-content">
       
          <div className="contact-info">
            <h2>{ContactContent.Heading}</h2>
            <div className="btn-group">
            <a  href={`tel:${telPhoneNumber}`}> CALL NOW:{PhoneNumber.PhoneNo}</a>
          </div>
          
          </div>
          
        
        </div>
      </section>
    </div>
  );
}
