import React from "react";
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import WhyChooseUs from "./components/WhyChooseUs";
import Text from "./components/Text";
import Services from "./components/Services";
import HowItWorks from "./components/HowItWorks";
import Contact from "./components/Contact";
import MobileNavbar from "./components/MobileNavbar";
import HeroImage from './assets/images/photos/hero1.jpg'
import Footer from "./components/Footer";
import ContactImage from './assets/images/photos/ct.png'
export default function landingPage() {
  const HeroContent = {
    Heading: `Your Best Move Starts Here`,
    SubHeading: `Top Movers at Your Service`,
    Text: ` We offer premier moving services across the US. Equipped to handle any move, big or small, we make your relocation seamless and stress-free.` , 
    Image: HeroImage ,
  }
  const ContactContent = {
    Heading: `Seamless Moves, Satisfied Customers
            Stress-Free Moving Starts Here`,
    Image: ContactImage ,
  }
  const PhoneNumber = {
    PhoneNo: "(833) 337-2008",
  };

  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection HeroContent={HeroContent} PhoneNumber={PhoneNumber}/>
      <WhyChooseUs PhoneNumber={PhoneNumber} />
      <HowItWorks PhoneNumber={PhoneNumber} />
      <Services PhoneNumber={PhoneNumber} />
      <Text/>
      <Contact ContactContent={ContactContent} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
