import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
import useLoadScript from "../Utility/useLoadScript";
import ExternalScriptsLoader from "../Scripts/Home_dl_lps";
import HowItWorks from "../components/HowItWorks";
import Contact from "../components/Contact";
import HeroImage from '../assets/images/photos/hero4.jpg';
import ContactImage from '../assets/images/photos/ct.png';
import WhyChooseUs from "../components/WhyChooseUs";
import Services from "../components/Services";
export default function Bng_lp1() {
  useLoadScript("../Scripts/Home_dl_lps.js");
  const HeroContent = {
    Heading: `Moving Made Easy `,
    SubHeading: `Relax and Relocate with Our Expert Movers`,
    Text: `` , 
    Image: HeroImage ,
  }
  const ContactContent = {
    Heading: `Achieve the Perfect Move with Our Expert Help at Budget-Friendly Rates`,
    Image: ContactImage ,
  }
  const PhoneNumber = {
    PhoneNo: "(833) 337-2008",
  };
  return (
    <div>
      <ExternalScriptsLoader />
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection HeroContent={HeroContent} PhoneNumber={PhoneNumber}/>
      <WhyChooseUs PhoneNumber={PhoneNumber} />
      
      <Services PhoneNumber={PhoneNumber} />
     
      <Contact ContactContent={ContactContent} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
