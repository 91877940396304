import React from "react";
import Navbar from "../components/Navbar";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
export default function Disclaimer() {
  const PhoneNumber = {
    PhoneNo: "(877) 403-2065",
  };
  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <div
        data-elementor-type="wp-page"
        data-elementor-id="62"
        class="elementor elementor-62"
      >
        
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-4e4f823 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="4e4f823"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d255527"
              data-id="d255527"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-19c86b4 elementor-widget elementor-widget-heading"
                  data-id="19c86b4"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h1 class="elementor-heading-title elementor-size-default">
                      DISCLAIMER
                    </h1>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-8242f22 elementor-widget elementor-widget-text-editor"
                  data-id="8242f22"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      <em>Last updated July 25, 2022</em>
                    </p>
                    <p>
                      The information provided by Prime Star Movers (“we,” “us,”
                      or “our”) on PrimeStarMovers.com (the “Site”) is for
                      general informational purposes only. All information on
                      the Site is provided in good faith, however we make no
                      representation or warranty of any kind, express or
                      implied, regarding the accuracy, adequacy, validity,
                      reliability, availability, or completeness of any
                      information on the Site. UNDER NO CIRCUMSTANCE SHALL WE
                      HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY
                      KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR
                      RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR USE
                      OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON THE
                      SITE IS SOLELY AT YOUR OWN RISK.
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="elementor-section elementor-top-section elementor-element elementor-element-b39d5d2 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="b39d5d2"
          data-element_type="section"
        >
          <div class="elementor-container elementor-column-gap-default">
            <div
              class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-14e6289"
              data-id="14e6289"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-d0bbbd7 elementor-widget elementor-widget-text-editor"
                  data-id="d0bbbd7"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      The Site cannot and does not contain Moving advice. The
                      Moving information is provided for general informational
                      and educational purposes only and is not a substitute for
                      professional advice. Accordingly, before taking any
                      actions based upon such information, we encourage you to
                      consult with the appropriate professionals. We do not
                      provide any kind of Moving advice. THE USE OR RELIANCE OF
                      ANY INFORMATION CONTAINED ON THE SITE IS SOLELY AT YOUR
                      OWN RISK.
                    </p>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-cbd2235 elementor-widget elementor-widget-heading"
                  data-id="cbd2235"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h4 class="elementor-heading-title elementor-size-default">
                      TCPA DISCLAIMER
                    </h4>{" "}
                  </div>
                </div>
                <div
                  class="elementor-element elementor-element-695b9f9 elementor-widget elementor-widget-text-editor"
                  data-id="695b9f9"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div class="elementor-widget-container">
                    <p>
                      Notwithstanding any current or prior election to opt in or
                      opt out of receiving telemarketing calls, or SMS messages
                      (text messages) from our representatives, agents,
                      affiliates, you expressly consent to be contacted by PRIME
                      STAR MOVERS, our agents, representatives, affiliates, or
                      anyone calling on our behalf. This is for any purposes
                      relating to your request for Moving service, at any
                      telephone number, or physical or electronic address you
                      provide or at which you may be reached. You agree we may
                      contact you in any way, including SMS messages (text
                      messages), calls using prerecorded messages or artificial
                      voice, and calls and messages delivered using auto
                      telephone dialing system or an automatic texting system.
                    </p>
                    <p>
                      Automated messages may be played when the telephone is
                      answered whether by you or someone else. In the event that
                      an agent or representative of PRIME STAR MOVERS calls, he
                      or she may also leave a message on your answering machine,
                      voice mail, or send one via text. You certify that the
                      telephone numbers that you have provided, are in fact your
                      contact numbers, and you agree to receive calls at each
                      phone number you have provided PRIME STAR MOVERS. You
                      agree to promptly alert PRIME STAR MOVERS in the event
                      that you stop using a particular phone number. Your
                      cell/mobile telephone provider will charge you according
                      to the type of account you carry with those companies. You
                      may opt out of any future contact via text message by
                      replying anytime with “STOP”. You agree that PRIME STAR
                      MOVERS may contact you by email, using any email address
                      you have provided or that you provide in the future. You
                      may opt out of any future contact via email message by
                      replying anytime with “UNSUBSCRIBE”. PRIME STAR MOVERS may
                      listen to and/or record calls between you and any
                      representative without notice, as permitted by applicable
                      laws. For example, we listen to calls to monitor for
                      quality purposes.
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
