import React, { useEffect, useState, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";

export default function HeroSection({ PhoneNumber, HeroContent }) {
  const cardRefs = [useRef(), useRef(), useRef()];
  const [textLength, setTextLength] = useState(0);
  const [animationClass, setAnimationClass] = useState('typing-animation');

  useEffect(() => {
    cardRefs.forEach((ref) => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);

  const formatPhoneNumberForTel = (number) => number.replace(/\D/g, "");
  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;

  useEffect(() => {
    setTextLength(HeroContent.SubHeading.length);

    // Add the looping class after the first animation completes
    const handleAnimationEnd = () => {
      setAnimationClass('typing-animation looping');
    };

    const timeout = setTimeout(() => {
      handleAnimationEnd();
    }, 4000); // Delay for initial animation completion (should match the typing animation duration)

    return () => clearTimeout(timeout);
  }, [HeroContent.SubHeading]);

  return (
    <div className="hero-section">
      <section className="hero-content">
        <div className="hero-overlay"></div>
        <div className="hero-container">
          <div className="hero-text-column animated fadeInLeft">
            <div className="hero-text-wrap">
              <div className="hero-heading">
                <h1>{HeroContent.Heading}</h1>
              </div>
              <div className="hero-heading">
                <h4
                  className={animationClass}
                  style={{
                    "--steps": textLength,
                  }}
                >
                  {HeroContent.SubHeading}
                </h4>
              </div>
              <div className="hero-description">
                <p>{HeroContent.Text}</p>
              </div>
              <div className="btn-group">
                <a href={`tel:${telPhoneNumber}`}>
                  {" "}
                  CALL NOW: {PhoneNumber.PhoneNo}
                </a>
              </div>
            </div>
          </div>
          <div className="hero-image-column animated zoomInDown">
            <div className="hero-image-wrap">
              <img src={HeroContent.Image} alt="Hero" className="hero-image" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
